import { InsuranceProvider, Product, SUPPORTED_STATE } from 'common.interfaces';
import { GENDER } from 'common.interfaces';

export enum QUOTES_ROUTE {
  BASE = 'quotes',
  BASE_FULL_PATH = '/quotes',
}

export const quoteStateKey = 'quote';

export enum QUOTE_VIEW {
  COVERAGE = 'coverage',
  BUDGET = 'budget',
}

export interface QuoteState {
  product: Product;
  carrier: InsuranceProvider;
  age: number;
  coverage: number;
  smoker: boolean;
  gender: GENDER;
  yearsOfCoverage: number;
  state: SUPPORTED_STATE | undefined;
  view: QUOTE_VIEW;
  budget: number;
}
